import { useRef, useMemo, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { Box } from '@mui/material'

import TileWrapper from '../../components/TileWrapper'
import DoFormsFormSettingsDialog from './DoformsFormSettingDialog'
import tileApi from 'apis/disApi/tileApi'

import { IconThemeProvider } from 'custom-components/context/IconThemesContext'
import { isJson, logErrorMessage } from '../../../../../utils/functions/helpers'
import { tileKeys } from '../../hooks/useTileQuery'

function DoFormsFormTile(props) {
	const { tile } = props
	const { environment } = useSelector((state) => state)
	const iconTheme = environment.theme.icons
	const { id: dashboardKey } = useParams()

	const queryClient = useQueryClient()

	const updateTileMutation = useMutation(tileApi.update, {
		onSuccess: () => queryClient.invalidateQueries(tileKeys.allWithKey(dashboardKey)),
	})

	const tileRef = useRef(null)

	const settings = useMemo(() => {
		if (tile?.settings && isJson(tile?.settings)) {
			return JSON.parse(tile?.settings ?? '{}')
		}

		return {}
	}, [tile?.settings])

	const { tileWidth, defaultUrl, defaultProjectForm, isReload } = settings

	const [newUrl, setNewUrl] = useState(defaultUrl || '')
	const [settingsOpen, setSettingsOpen] = useState(false)
	const [urlSubmit, setUrlSubmit] = useState('')
	const [isReloadFrm, setIsReloadFrm] = useState(undefined)
	const [refreshValue, setRefreshValue] = useState(0)
	const innerRef = useRef(null)

	useEffect(() => {
		setNewUrl(settings?.defaultUrl)
	}, [defaultUrl])

	useEffect(() => {
		setIsReloadFrm(settings?.isReload)
	}, [isReload])

	useEffect(() => {
		const ifrm = innerRef.current
		if (!newUrl) {
			setNewUrl(newUrl)
			ifrm.removeEventListener('load', handler)
			return
		}

		ifrm.addEventListener('load', handler)

		if (refreshValue === 2) {
			handleSubmitted()
		}

		return () => ifrm.removeEventListener('load', handler)
	}, [newUrl, isReloadFrm, refreshValue])

	const handleOpenDialog = () => {
		setSettingsOpen(true)
	}

	const handleCloseDialog = () => {
		setSettingsOpen(false)
	}

	function addTokenToURL(url) {
		if (url?.search('&token=') < 0) {
			return url + '&token=' + environment.apiToken
		} else {
			return url
		}
	}

	const handleSubmitSetting = async (data) => {
		try {
			const { defaultUrl, defaultProjectForm, connectedDatagridKey, isReload } = data
			await updateTileMutation.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: {
					settings: JSON.stringify({
						...settings,
						defaultUrl,
						connectedDatagridKey,
						defaultProjectForm,
						isReload,
					}),
				},
				token: environment.apiToken,
			})
			handleCloseDialog()
		} catch (error) {
			logErrorMessage(error)
		} finally {
			handleCloseDialog()
		}
	}

	const handleResizeTileWidth = async (width) => {
		try {
			const editedSettings = JSON.stringify({
				...settings,
				tileWidth: width,
			})

			await updateTileMutation.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: { settings: editedSettings },
				token: environment.apiToken,
			})
		} catch (error) {
			logErrorMessage(error)
		}
	}
	const handler = (event) => {
		if (event?.type === 'load') {
			if (refreshValue === 1) {
				setRefreshValue(2)
			} else if (refreshValue === 0) {
				setRefreshValue(1)
			}
		}
	}

	const handleSubmitted = () => {
		if (settings?.isReload === true || settings?.isReload === undefined) {
			const d = new Date()
			setUrlSubmit(defaultUrl + '&time:' + d.getTime())
			setRefreshValue(0)
		}
	}

	return (
		<IconThemeProvider values={iconTheme}>
			<TileWrapper
				title={tile?.i}
				onSettingClick={handleOpenDialog}
				ref={tileRef}
				isExpandDialogBtn
			>
				<DoFormsFormSettingsDialog
					tileElementWidth={tileRef?.current?.clientWidth}
					defaultTileWidth={tileWidth}
					tile={tile}
					settings={settings}
					isSubmitting={updateTileMutation.isLoading}
					open={settingsOpen}
					onClose={handleCloseDialog}
					onSubmit={handleSubmitSetting}
					onResizeTileWidth={handleResizeTileWidth}
				/>

				<Box
					sx={{
						position: 'absolute',
						left: 0,
						right: 0,
						top: 30,
						bottom: 0,
						background: '#fff',
						display: 'flex',

						'& .MuiDataGrid-selectedRowCount': {
							opacity: '0 !important',
						},
					}}
				>
					<iframe src={urlSubmit || newUrl} width="100%" ref={innerRef} allow="geolocation" />
				</Box>
			</TileWrapper>
		</IconThemeProvider>
	)
}

export default DoFormsFormTile
