import { Box, FormControl, InputLabel, MenuItem, Select, Skeleton, Stack } from '@mui/material'
import useFields from 'components/pages/dis/hooks/useFields'
import useView from 'components/pages/dis/hooks/useView'
import { useTranslation } from 'react-i18next'
import OtherOptions from '../../_components/OtherOptions'
import SelectFields from '../../_components/SelectFields'
import DashboardList from './DashboardList'
import DoformsDataSaveViewDialog from 'components/data/datagrid/DoformsDataSaveViewDialog'
import { useDispatch, useSelector } from 'react-redux'
import {
	checkAdminRole,
	getAggregationModel,
	getRowGroupingModel,
	getSortModel,
	isNumber,
	getSizedSessionStorage,
	saveSizedSessionStorage,
} from 'utils/functions/helpers'

import { loadNextRecordsQuery } from '../../../../../components/data/dataServices'

import {
	parsedDataGridColumns,
	parsedDataGridRecords,
} from '../../../../../components/data/dataHelpers'

import { getViewTabViewRecords, getAllViews } from '../../../../../components/data/dataServices'

import { isEmpty, isEqual } from 'lodash'
import { useEffect, useState, memo, useMemo, Fragment } from 'react'
import { VIEW } from '../../../../../constants'

const DatagridSettings = ({
	dashboardKeyList,
	filterData,
	onFilterDataChange,
	setColumnList,
	setLoadingViewData,
}) => {
	const { t } = useTranslation('common')
	const { environment } = useSelector((state) => state)

	const OTHER_OPTIONS = [
		{ label: t('common:chart.showDataNames'), value: 'showDataNames' },
		{
			label: t('common:chart.showRepeatables'),
			value: 'showRepeatables',
		},
		{ label: t('common:chart.enableDataExport'), value: 'enableExport' },
		{
			label: t('common:chart.enableActiveUpdate'),
			value: 'enableActiveUpdate',
		},
		{
			label: t('common:chart.allowViewEdit'),
			value: 'allowViewEdit',
		},
		{
			label: t('common:chart.hideHeaders'),
			value: 'hideHeaders',
		},
		{
			label: t('common:chart.hideTotalRows'),
			value: 'hideTotalRows',
		},
	]

	const isFormList = filterData?.type === 'FormList'

	const formattedOtherOptions = OTHER_OPTIONS.map((item) => {
		if (isFormList && item.value !== 'enableActiveUpdate') {
			return { ...item, hide: true }
		}
		return item
	})

	const [views, setViews] = useState([])
	// const [viewLoading, setViewsLoading] = useState(false)
	const { viewLoading, views: allViews, reloadViews } = useView()

	const [selectedView, setSelectedView] = useState({})

	const [loadingFields, setLoadingFields] = useState(false)

	const [viewData, setViewData] = useState({})
	const [clientFilter, setClientFilter] = useState([])
	const [columns, setColumns] = useState([])
	const [query, setQuery] = useState({})
	const [queryView, setQueryView] = useState({})
	const [records, setRecords] = useState({})
	const [gridRows, setGridRows] = useState([])

	const [sortModel, setSortModel] = useState([])
	const [aggregationModel, setAggregationModel] = useState({})
	const [rowGroupingModel, setRowGroupingModel] = useState([])
	const [colorConditions, setColorConditions] = useState({})

	function fetchData() {
		if (!selectedView.key || !selectedView.projectKey) return
		setLoadingViewData(true)
		setLoadingFields(true)
		getViewTabViewRecords({
			viewKey: selectedView.key,
			viewSession: false,
			token: environment.apiToken,
		})
			.then((response) => {
				let responseData = response.data

				const clientFilter = responseData?.clientFilter
					? JSON.parse(responseData?.clientFilter)
					: undefined
				if (clientFilter) {
					setClientFilter(clientFilter)
				}
				setViewData(responseData)
				setColumns(responseData.columns)
				setQuery(responseData.queries[0])
				const colorConditions = isEmpty(responseData?.colorConditions)
					? {}
					: JSON.parse(responseData?.colorConditions)
				setColorConditions(colorConditions)

				loadNextRecordsQuery(selectedView, false, environment.apiToken)
					.then((response) => {
						let newDatas = []
						response.data.records.forEach((item) => {
							const existedRecords = newDatas.filter((filterItem, index) => {
								if (item.submissionKey === filterItem.submissionKey) {
									newDatas[index] = item
									return true
								}
								return false
							})
							if (existedRecords.length === 0) {
								newDatas.push(item)
							}
						})

						setQueryView(response.data.view)
						setRecords(newDatas)
					})
					.catch((err) => {
						// if (err.response?.data) {
						// 	setError('Code ' + err.response.data.code + ': ' + err.response?.data?.message)
						// } else {
						// 	setError(err.message)
						// }
						console.log('loadNextRecordsQuery', err)
					})
			})
			.catch((err) => {
				// if (err.response?.data) {
				// 	setError('Code ' + err.response.data.code + ': ' + err.response?.data?.message)
				// } else {
				// 	setError(err.message)
				// }
				console.log('getViewTabViewRecords', err)
			})
			.finally(() => {
				setLoadingViewData(false)
				setLoadingFields(false)
			})
	}

	useEffect(() => {
		setLoadingViewData(true)
		setViews(allViews)
		setLoadingViewData(false)
	}, [allViews])

	useEffect(() => {
		fetchData()
	}, [selectedView])

	useEffect(() => {
		if (isEmpty(viewData)) {
			return
		}

		const sortModel = getSortModel(viewData.columns, viewData?.sorts)
		setSortModel(isEmpty(sortModel) ? [] : sortModel)
	}, [viewData])

	const hasManageRights = useMemo(() => {
		if (!environment) return []

		return checkAdminRole(environment?.userCurrent) || false
	}, [columns])

	const gridColumns = useMemo(() => {
		if (!columns) return []
		if (!columns.length) return []
		return parsedDataGridColumns(environment, records, columns)
	}, [columns])

	useEffect(() => {
		// Set filter model base on columns
		if (!columns) return

		const aggregationModel = getAggregationModel(columns)
		setAggregationModel(isEmpty(aggregationModel) ? [] : aggregationModel)

		const rowGroupingModel = getRowGroupingModel(columns)
		setRowGroupingModel(isEmpty(rowGroupingModel) ? [] : rowGroupingModel)
	}, [columns])

	useEffect(() => {
		if (isEmpty(records)) return
		const gridRowsTmpSession = getSizedSessionStorage('grid-rows-tmp')
		const gridRowsTmpSessionData = isEmpty(gridRowsTmpSession) ? [] : gridRowsTmpSession
		if (gridRowsTmpSessionData.length > 0) {
			setGridRows(gridRowsTmpSessionData)
			return
		}
		let gridRowsTmp = parsedDataGridRecords(records, columns, environment, [])
		saveSizedSessionStorage('grid-rows-tmp', gridRowsTmp)

		const keyInGridRowsTmp = gridRowsTmp?.[0] ? Object.keys(gridRowsTmp[0]) : []
		window.sessionStorage.setItem('full_columns_keys', JSON.stringify(keyInGridRowsTmp))
		setGridRows(gridRowsTmp)
	}, [records])

	useEffect(() => {
		setColumnList(isEmpty(columns) ? [] : columns)
	}, [columns])

	useEffect(() => {
		if (filterData?.viewKey === selectedView?.key) return

		const viewByKey = views.find((view) => view.key === filterData?.viewKey)
		setSelectedView(viewByKey || {})
	}, [views, filterData?.viewKey])

	const handleFieldsChange = (id, value) => {
		if (value === '') {
			const newLinkedFields = { ...filterData.linkedFields }
			delete newLinkedFields[id]
			onFilterDataChange('linkedFields', newLinkedFields)
		} else {
			const newLinkedFields = {
				...filterData.linkedFields,
				[id]: value,
			}
			onFilterDataChange('linkedFields', newLinkedFields)
		}
	}

	return (
		<div>
			<Stack spacing={2}>
				{!isFormList && (
					<Box>
						{!viewLoading && !loadingFields ? (
							<ViewSelectComponent
								viewKey={selectedView?.key || ''}
								views={views}
								onFilterDataChange={onFilterDataChange}
								setSelectedView={setSelectedView}
								setColumns={setColumns}
								setColumnList={setColumnList}
							/>
						) : (
							<Skeleton variant="rectangular" height={40} width="50%" />
						)}
					</Box>
				)}

				{!isFormList && (
					<Box>
						<SelectFields
							columnList={columns}
							isColumnListLoading={loadingFields}
							primaryKeyList={filterData?.primaryKeyList}
							columnsChecked={filterData?.columnsChecked}
							otherOptionsChecked={filterData?.otherOptionsChecked}
							onChangeColumnsCheck={(value) => onFilterDataChange('columnsChecked', value)}
							onChangePrimaryKeyList={(newPrimaryKeyList) => {
								onFilterDataChange('primaryKeyList', newPrimaryKeyList)
							}}
						/>
					</Box>
				)}

				{dashboardKeyList?.length > 0 && (
					<Box>
						<DashboardList
							title={t('common:dis.dashboardKeys')}
							list={dashboardKeyList}
							fields={columns}
							onFieldsChange={handleFieldsChange}
							linkedFields={filterData?.linkedFields ?? {}}
							getLabelOption={(item) => item.name}
						/>
					</Box>
				)}

				<Box>
					<OtherOptions
						optionList={formattedOtherOptions}
						checkedOptions={filterData?.otherOptionsChecked}
						onChangeOptions={(value) => onFilterDataChange('otherOptionsChecked', value)}
					/>
				</Box>
			</Stack>
			<Fragment>
				{environment.showAddView && isEmpty(selectedView) && (
					<DoformsDataSaveViewDialog
						tab={VIEW.TAB_TILE_BUILDER}
						densityParam={'compact'}
						isAddNew={true}
						additionSaveCallBack={() => {
							reloadViews()
						}}
					/>
				)}
				{environment.showEditView && !isEmpty(selectedView) && (
					<DoformsDataSaveViewDialog
						clientFilter={clientFilter}
						hasManageRightsParam={hasManageRights}
						environmentParam={environment}
						ownersParam={environment.owners}
						gridRows={gridRows}
						gridColumnsParam={gridColumns}
						viewData={viewData}
						queryView={queryView}
						tab={VIEW.TAB_TILE_BUILDER}
						// recordsLoading={recordsLoading}
						aggregationModel={aggregationModel}
						rowGroupingModel={rowGroupingModel}
						sortModel={sortModel}
						colorConditions={colorConditions}
						densityParam={'compact'}
						isAddNew={false}
						records={records}
						additionSaveCallBack={() => {
							reloadViews()
							fetchData()
						}}
					/>
				)}
			</Fragment>
		</div>
	)
}

function ViewSelectComponent(props) {
	//filterData?.viewKey
	const { viewKey, views, onFilterDataChange, setSelectedView, setColumns, setColumnList } = props

	const [t] = useTranslation('common')

	const options = useMemo(() => {
		const result = [
			{
				key: 'emptyViewKey',
				value: '',
				label: 'None',
			},
		]
		if (isEmpty(views)) return result

		views
			.sort((a, b) => a.name.localeCompare(b.name))
			.forEach((view) => {
				result.push({
					key: view.key,
					value: view.key,
					label: view.name,
				})
			})
		return result
	}, [views])

	if (!views) return null
	return (
		<Stack sx={{ width: '100% !important' }} direction="row" spacing={2}>
			<FormControl size="small" fullWidth sx={{ width: '50% !important' }}>
				<InputLabel id="view-select-small-label">{t('common:input.view')}</InputLabel>
				<Select
					labelId="view-select-small-label"
					id="view-select-small"
					value={isEmpty(views) ? '' : viewKey || ''}
					label={t('common:input.view')}
					name="viewKey"
					onChange={(event) => {
						if (isEmpty(event.target.value)) {
							// None View Value
							onFilterDataChange('viewKey', '')
							setSelectedView({})
							setColumns([])
							setColumnList([])
							return
						}
						onFilterDataChange('viewKey', event.target.value)

						const viewByKey = views.find((view) => view.key === event.target.value)
						setSelectedView(viewByKey || {})
					}}
				>
					{options.map((option) => (
						<MenuItem value={option.value} key={option.key}>
							{option.label}
						</MenuItem>
					))}
					{/* <MenuItem value="">
							<em>None</em>
						</MenuItem>
						{views?.length > 0 &&
							views
								.sort((a, b) => a.name.localeCompare(b.name))
								.map((viewItem) => (
									<MenuItem value={viewItem.key} key={viewItem.key}>
										{viewItem.name}
									</MenuItem>
								))} */}
				</Select>
			</FormControl>
		</Stack>
	)
}

function areEqual(preProps, nextProps) {
	return (
		isEqual(preProps.dashboardKeyList, nextProps.dashboardKeyList) &&
		isEqual(preProps.filterData, nextProps.filterData)
	)
}

const MemorizedDatagridSettings = memo(DatagridSettings, areEqual)
export default MemorizedDatagridSettings
